<template>
	<div :class="['type' + content.type ,'awardsContainer']" ref="awardsContainer">
		<div class="container">
			<div class="h3 mainTitle mb60 color-gray animationBlockFadeUp" v-html="content.title"></div>
			<div :class="[{'color-white': content.type != 2}, 'awards']">
				<div class="awards__item animationBlockFadeUp" v-for="(item, index) in content.items">
					<div class="awards--icon" v-html="item.icon"></div>
					<div class="awards__content">
						<p class="awards--desc" v-html="item.desc" v-if="item.desc"></p>
						<p class="awards--title" v-html="item.title" v-if="item.title"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Awards'
</style>

<script>
	export default {
		props: ['content'],
		data: () => ({
		}),
		mounted(){
		},
		methods: {
		},
		components: {
		}
	}
</script>
